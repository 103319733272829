<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2>ระบบเบิกค่าใช้จ่าย</h2>
          </b-col>
          <b-col  md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="searchIncentiveOt"
                  @keydown.enter.prevent="onSearchIncentiveOt"
                />
                <b-input-group-append is-text @click="onSearchIncentiveOt">
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <v-select
                v-if="mode=='admin'"
                style="width: 40%"
                id="employee"
                v-model="employeeSelected"
                label="fullname"
                :options="employeeOption"
                class="ml-1"
                :clearable="false"
              />

              <b-button
                v-if="accountUserPermission=='create' || accountUserPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                @click="$router.push('/accounting/create')"
                >สร้างใบเบิก <feather-icon icon="PlusCircleIcon" size="14"
              /></b-button>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="incentiveOtList"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
              show-empty
            >
              <template #empty="scope">
                  <center>ค้นหาไม่พบ</center>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>



              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(processName)="row" >
                <center>
                  <b-badge pill v-if="row.item.processNo>=4" variant="success">{{row.item.processName}}</b-badge>
                  <b-badge pill v-else-if="row.item.processNo==0" variant="secondary">แก้ไข</b-badge>
                  <b-badge pill v-else variant="info">{{row.item.processName}}</b-badge>
              </center>
              
              </template>

              <template #cell(action)="row" v-if="accountUserPermission=='create' || accountUserPermission=='edit'">
                <center>
                  <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    pill
                    @click="$router.push(`/accounting/edit/${row.item.id}`)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer text-secondary"
                    />
                  </b-button>
                  <b-button variant="danger" size="sm" pill @click="onDeleteIncentiveOtById(row.item.id)" :disabled="row.item.processNo!==0">
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer text-white"
                    />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
//COMPONENTS
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner,
} from "bootstrap-vue";

//FIELD
import Field from "./field";

//SERVICE
import UserService from "@/services/user";
import IncentiveOTService from "@/services/incentiveOT";



export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      incentiveOtField: Field,
      incentiveOtList: [],
      employeeSelected: {},
      employeeOption: [],
      mode: "user",
      isLoading: false,
      searchIncentiveOt:""
    };
  },

  watch: {
    employeeSelected() {
      this.getIncentiveOtList();
    },
  },

  async created(){   
    if(this.showDropdownUsersPermission =='view'){
      this.mode = "admin"
    }

    if(this.mode == "admin"){
      await this.getUserList();
    }
  },

  computed :{
    accountUserPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("AC001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    showDropdownUsersPermission () {
      return this.$store.getters["user/getPermissionPage"]("AC001-1")
    },
    visibleFields() {
        return this.incentiveOtField.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("AC001") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }
  },



  methods: {
    async getUserList() {
      try {
        this.isLoading = true
        const result = await UserService.GetUserList({}, "type=employee");
        this.employeeOption = result.data.data;
        this.employeeSelected = result.data.data[0];   
        this.isLoading = false
      } catch (error) {
        alert("Load employee data fail!");
        console.log(error);
      }
    },
    async onSearchIncentiveOt(){
      const result = await IncentiveOTService.SearchIncentiveOt(this.searchIncentiveOt)
      this.incentiveOtList = result.data || [];
    },
    async getIncentiveOtList() {
      try {
        this.isLoading = true;
        const userLoginData = localStorage.getItem("userData");
        const userData = JSON.parse(userLoginData);

        let employeeIdSelected = userData.userId;
        if (this.mode == "admin") {
          employeeIdSelected = this.employeeSelected.userId;
        }

        const result = await IncentiveOTService.GetIncentiveOTListByEmp({},employeeIdSelected);
        this.incentiveOtList = result.data || [];
        this.totalRows = this.incentiveOtList.length;
        this.isLoading = false;
    
      } catch (error) {
        alert("Load incentive ot data fail!");
        console.log(error);
      }
    },

    async onDeleteIncentiveOtById(incentiveOtId){
      try {
        const isConfirm = confirm("Are you sure you want to delete")
        if(!isConfirm) return ;

        const result = await IncentiveOTService.DeleteIncentiveOT(incentiveOtId)
        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "CheckIcon",
              variant: "success",
              text: `ลบใบเบิกค่าเดินทางเรียบร้อย`,
            },
          });  
          this.getIncentiveOtList()        
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการลบใบเบิกค่าเดินทาง`,
            },
          });
        }
      } catch (error) {
        alert("Delete IncentiveOT data fail!");
        console.log(error)
      }      
      
    }, 
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {


    await this.getIncentiveOtList();
    
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
